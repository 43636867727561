<template>
  <ui-validate @status="({ detail }) => formStatus = detail">
    <GenericCardWrapperComponent
      data-test-id="logbook_trip-card"
      class="w-100"
      :open="!isOpen"
    >
      <template #header>
        <div class="d-flex align-items-baseline p-4">
          <ui-checkbox
            v-if="isMergeEnabled"
            :value="isSelected"
            :size="SIZES.small"
            data-test-id="toggle_is_selected-checkbox"
            @changevalue="() => $emit('toggle:is-selected')"
          />
          <ui-icon
            :icon="isValid ? ICONS.check : ICONS.alertFull"
            :color="isValid ? COLORS.success: COLORS.danger"
            :size="ICONS_SIZES.small"
            data-test-id="is_valid-icon"
            class="mr-2"
          />
          <ui-icon
            :icon="ICONS.calendar"
            :size="ICONS_SIZES.small"
          />
          <p
            class="emobg-font-weight-bold ml-2 mr-3"
            data-test-id="trip_number-text"
          >
            {{ $t('common.trip') }} {{ tripNum }}
          </p>
          <span
            class="emobg-font-small emobg-color-ink-light"
            data-test-id="waypoints_date-text"
          >
            {{ formatDate(get(first(wayPoints), 'ignitionStart'), datetimeFormats.shortTimeDayMonth) }}
            /
            {{ formatDate(get(last(wayPoints), 'ignitionStart'), datetimeFormats.shortTimeDayMonth) }}
          </span>
        </div>
        <div class="d-flex ml-auto p-4">
          <ui-button
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
            :size="SIZES.small"
            class="px-2"
            data-test-id="open_trip-button"
            @clickbutton="() => $emit('toggle:is-open')"
          >
            {{ $t('buttons.more_details') }}
            <ui-icon
              :icon="isOpen ? ICONS.arrowDown : ICONS.arrowUp"
              :size="ICONS_SIZES.small"
              class="d-block ml-3"
            />
          </ui-button>
        </div>
      </template>
      <template #collapsable-content>
        <div class="d-flex flex-column px-4 pb-3">
          <div class="d-flex">
            <div class="d-flex flex-column w-50 pr-4">
              <RideshareLocationLineComponent
                :rideshare-stops="wayPoints"
                class="d-flex flex-column pb-4"
              />

              <div class="d-flex mb-2">
                <ui-icon
                  :icon="exceededMileage ? ICONS.alertFull : ICONS.check"
                  :color="exceededMileage ? COLORS.danger : COLORS.success"
                  :size="ICONS_SIZES.small"
                  data-test-id="exceed_mileage-icon"
                  class="mr-2"
                />
                <div
                  :class="exceededMileage
                    ? 'emobg-color-danger'
                    : 'emobg-color-success'"
                >
                  <strong
                    class="d-block emobg-font-large"
                    data-test-id="exceed_mileage_number-text"
                  >
                    {{ mileage }}
                  </strong>
                  <span data-test-id="exceed_mileage_message-text">
                    {{ exceededMileage
                      ? $t('logbook.total_distance_tolerance_not_matching')
                      : $t('logbook.total_distance_tolerance') }}
                  </span>
                </div>
              </div>

              <ui-text-area
                v-if="exceededMileage"
                v-validate="{ isRequired: true }"
                :value="comment"
                :label="$t('LogbookView.LogbookTrip.inputs.comment.text_area.label')"
                :size="SIZES.small"
                :placeholder="$t('LogbookView.LogbookTrip.inputs.comment.text_area.placeholder')"
                :disabled="areInputsDisabled"
                data-test-id="comment-textarea"
                name="comment"
                class="w-100 mt-3"
                rows="5"
                @changevalue="({ detail }) => comment = detail"
              />

              <ui-checkbox
                ref="isOvernight"
                :checked="isOvernight"
                :label="$t('LogbookView.LogbookTrip.inputs.overnight_booking.checkbox.label')"
                :size="SIZES.small"
                :disabled="areInputsDisabled"
                class="pt-4 text-wrap"
                name="is_overnight"
                data-test-id="is_overnight-checkbox"
                @changevalue="({ detail }) => isOvernight = toBoolean(detail)"
              />

              <ui-select
                v-if="isOvernight"
                ref="overnightReason"
                v-validate.select="{ isRequired: true }"
                :value="overnightReason"
                :options.prop="availableOvernightReasons"
                :label="$t('LogbookView.LogbookTrip.inputs.overnight_booking.checkbox.label')"
                :disabled="areInputsDisabled"
                :placeholder="$t('LogbookView.LogbookTrip.inputs.overnight_booking.checkbox.label')"
                name="overnight-reason"
                data-test-id="overnight_reason-select"
                class="w-100 text-wrap"
                @selectoption="({ detail }) => overnightReason = detail"
              />
            </div>
            <div class="w-50 pl-4">
              <ui-select
                ref="purpose"
                v-validate.select="{ isRequired: true }"
                :value="purpose"
                :placeholder="$t('LogbookView.LogbookTrip.inputs.purpose.select.label')"
                :label="$t('LogbookView.LogbookTrip.inputs.purpose.select.label')"
                :options.prop="availablePurposes"
                :disabled="areInputsDisabled"
                class="w-100 py-2"
                data-test-id="purpose-select"
                name="purpose"
                @selectoption="({ detail }) => purpose = detail"
              />
              <template v-if="!isHidingPurposeSelected">
                <ui-text-input
                  ref="reason"
                  v-validate.blurinput="{ isRequired: true }"
                  :value="reason"
                  :label="$t('LogbookView.LogbookTrip.inputs.reason.text.label')"
                  :placeholder="$t('LogbookView.LogbookTrip.inputs.reason.text.placeholder')"
                  :disabled="areInputsDisabled"
                  data-test-id="reason-input_text"
                  class="w-100 py-2"
                  name="reason"
                  @changevalue="({ detail }) => reason = detail"
                />

                <ui-text-input
                  ref="clientName"
                  v-validate.blurinput="{ isRequired: true }"
                  :value="clientName"
                  :label="$t('LogbookView.LogbookTrip.inputs.client_name.text.label')"
                  :placeholder="$t('LogbookView.LogbookTrip.inputs.client_name.text.placeholder')"
                  :disabled="areInputsDisabled"
                  class="w-100 py-2"
                  data-test-id="who_did_you_visit-input_text"
                  name="client_name"
                  @changevalue="({ detail }) => $emit('on:update:trip', { clientName: detail })"
                />

                <ui-location-input
                  v-validate.blurinput="{
                    isRequired: true,
                  }"
                  :value="clientAddress"
                  :label="$t('LogbookView.LogbookTrip.inputs.client_address.input_location.label')"
                  :placeholder="$t('LogbookView.LogbookTrip.inputs.client_address.input_location.placeholder')"
                  :custom="[]"
                  :gkey="googleMapsKey"
                  :disabled="areInputsDisabled"
                  class="d-block"
                  data-test-id="client_address-input_location"
                  @changeplace="({ detail }) => $emit('on:update:trip', { clientAddress: get(detail, 'address') })"
                />
              </template>
            </div>
          </div>

          <ui-button
            v-bind="fetchButtonSpecs()"
            :disabled="!formStatus.isValid || !hadChanged || areInputsDisabled"
            :loading="isLoadingSaveTrip"
            class="ml-auto my-2"
            data-test-id="save_trip-button"
            @clickbutton="_value => $emit('on:save:trip')"
          >
            {{ $t('buttons.save') }}
          </ui-button>
        </div>
      </template>
    </GenericCardWrapperComponent>
  </ui-validate>
</template>

<script>
import includes from 'lodash/includes';
import snakeCase from 'lodash/snakeCase';
import map from 'lodash/map';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import { formatDate, toBoolean } from '@emobg/web-utils';

import { Validate } from '@emobg/vue-base';
import cfg from '@/config';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

import GenericCardWrapperComponent from '@/components/GenericCardWrapper/GenericCardWrapperComponent';
import RideshareLocationLineComponent from '@/domains/Carpooling/components/CarpoolingRideshareComponent/components/RideshareLocationLineComponent';
import { useTheme } from '@/composable/Theme/useTheme';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

import { HIDING_FIELDS_PURPOSES, PURPOSES } from './constants/purposes.const';

export default {
  name: 'LogbookTrip',

  components: {
    RideshareLocationLineComponent,
    GenericCardWrapperComponent,
  },

  directives: {
    Validate,
  },

  props: {
    clientAddress: {
      type: [Object, String],
      default: () => ({ address: '' }),
    },
    clientName: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
    exceededMileage: {
      type: Boolean,
      default: false,
    },
    isOvernight: {
      type: Boolean,
      default: false,
    },
    mileage: {
      type: String,
      default: '',
    },
    overnightReason: {
      type: String,
      default: null,
    },
    purpose: {
      type: String,
      default: PURPOSES.wayIn,
    },
    reason: {
      type: String,
      default: undefined,
    },
    wayPoints: {
      type: Array,
      default: () => ([]),
    },
    tripNum: {
      type: Number,
      default: undefined,
    },
    availaibleReasons: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isMergeEnabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    hadChanged: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingSaveTrip: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  data() {
    return {
      formStatus: { isValid: false },
      googleMapsKey: cfg.data.googleMapsKey,
    };
  },

  computed: {
    isHidingPurposeSelected() {
      return includes(HIDING_FIELDS_PURPOSES, this.purpose);
    },
    areInputsDisabled() {
      return this.isLoadingSaveTrip || this.isMergeEnabled || this.isLoading;
    },
  },

  watch: {
    isOvernight(isOvernight) {
      this.$emit('on:update:trip', { isOvernight, overnightReason: null });
    },
    overnightReason(overnightReason) {
      this.$emit('on:update:trip', { overnightReason });
    },
    comment(comment) {
      this.$emit('on:update:trip', { comment });
    },
    reason(reason) {
      this.$emit('on:update:trip', { reason });
    },
    purpose(purpose) {
      this.$emit('on:update:trip', { purpose });
    },
    formStatus(status) {
      this.$emit('on:update:trip', { isValid: status.areAllValidated });
    },
  },

  created() {
    googleMapsLoader.createInstance();
    this.datetimeFormats = DATETIME_FORMATS;
    this.availableOvernightReasons = map(this.availaibleReasons.availableOvernightReasons,
      (reasonTranslation, reasonKey) => ({
        label: reasonTranslation,
        value: snakeCase(reasonKey),
      }));
    this.availablePurposes = map(this.availaibleReasons.availablePurposes,
      (purposeTranslation, reasonKey) => ({
        label: purposeTranslation,
        value: snakeCase(reasonKey),
      }));
  },
  methods: {
    toBoolean,
    get,
    first,
    last,
    formatDate,
  },
};

</script>
